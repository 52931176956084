import React from "react";

// components

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className="relative bg-darkgreen-mju md:pt-32 pb-64 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          
        </div>
      </div>
    </>
  );
}
