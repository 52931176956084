/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { createPopper } from "@popperjs/core";
import { useHistory } from "react-router-dom";
import Hamburger from "components/Hamburger";
// components
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";
import * as Storage from "../../services/Storage.service";
const locale = require("react-redux-i18n").I18n;

const AdminTopNavBar = ({ setLocale }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [isThai, setIsThai] = React.useState(true);
  const [isOpenHam, setIsOpenHam] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const history = useHistory();
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // dropdown props
  const [dropdownAdminPopUpShow, setDropDownAdminOverShow] =
    React.useState(false);
  const btnDropDownAdminRef = React.createRef();
  const popoverAdminRef = React.createRef();
  const openDropDownPopUp = () => {
    createPopper(btnDropDownAdminRef.current, popoverAdminRef.current, {
      placement: "bottom-start",
    });
    setDropDownAdminOverShow(true);
  };
  const closeDropDownPopUp = () => {
    setDropDownAdminOverShow(false);
  };

  const ChangeTranslate = (e) => {
    if (e.target.id === "thaix") {
      setIsThai(true);
      Storage.SetLanguage("th");
      setLocale("th");
    } else {
      setIsThai(false);
      Storage.SetLanguage("en");
      setLocale("en");
    }
    localStorage.setItem("translate", e.target.id === "thaix" ? true : false);
  };

  useEffect(() => {
    resizeWindow();
    const isValue = localStorage.getItem("translate");
    if (isValue === null) {
      Storage.SetLanguage("th");
      setIsThai(true);
      setLocale("th");
    } else {
      setIsThai(isValue === "true");
      if (isValue === "true") {
        setLocale("th");
        Storage.SetLanguage("th");
      } else {
        setLocale("en");
        Storage.SetLanguage("en");
      }
    }

    const checkIfClickedOutside = (e) => {
      if (
        (dropdownAdminPopUpShow &&
          e.toElement.id !== "thaix" &&
          e.toElement.id !== "engx" &&
          e.toElement.id !== "ham") ||
        e.toElement.id === ""
      ) {
        setDropDownAdminOverShow(false);
      } else if (e.toElement.id === "") setDropDownAdminOverShow(false);
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const ClickHome = () => {
    const RoleUser = localStorage.getItem("roleUser");
    if (window.location.href.includes("admin") || RoleUser === "1") {
      history.push("/admin");
    } else {
      history.push("/home");
    }
  };

  const toggleHamburger = () => {
    setIsOpenHam(!isOpenHam);
  };

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap justify-between px-2 py-2 navbar-expand-lg bg-white">
        <div
          className={"hamburger mt-2"}
          style={{ display: windowWidth < 1024 ? "block" : " none" }}
          onClick={toggleHamburger}
        >
          <Hamburger isOpen={isOpenHam} />
        </div>
        <div className=" flex flex-wrap items-center justify-between" style={{ display: windowWidth < 1024 ? "none" : " flex" }}>
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li
                className={
                  "flex items-center text-lg font-bold text-green-mju " +
                  (windowWidth < 1024 ? " hidden" : "block")
                }
              >
                <img
                  alt="..."
                  src={require("assets/img/Group_1.png").default}
                  className="h-auto align-middle border-none max-w-350-px"
                />
              </li>
              <li
                className={
                  "flex items-center text-lg font-bold text-green-mju " +
                  (windowWidth < 1024 ? " hidden" : "block")
                }
              >
                &nbsp; | &nbsp;
              </li>
              <li
                className="flex items-center text-2xl font-bold text-green-mju cursor-pointer THSarabun"
                onClick={() => {
                  ClickHome();
                }}
              >
                <span className={windowWidth > 912 ? " block" : " hidden"}>
                  {" "}
                  Organic lessons{" "}
                </span>
                <div
                  className={
                    "image-logo " + (windowWidth > 912 ? " hidden" : " block")
                  }
                >
                  <img
                    alt="..."
                    src={require("assets/img/Group_1.png").default}
                    className={"  align-middle border-none max-w-350-px"}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="parent-div flex flex-wrap items-center justify-between w-3">
          <div className="text-sm font-bold text-green-mju">
            {" "}
            <i className="fas fa-globe "></i>&nbsp;&nbsp;{" "}
          </div>
          <div
            id="thaix"
            className={
              "text-sm font-bold text-green-mju cursor-pointer " +
              (!isThai ? "opacity-75" : "textUnderline")
            }
            onClick={(e) => {
              ChangeTranslate(e);
            }}
          >
            {" "}
            TH{" "}
          </div>
          <div className="text-sm font-bold text-green-mju">
            {" "}
            &nbsp;|&nbsp;{" "}
          </div>
          <div
            id="engx"
            className={
              "text-sm font-bold text-green-mju cursor-pointer " +
              (isThai ? "opacity-75" : "textUnderline")
            }
            onClick={(e) => {
              ChangeTranslate(e);
            }}
          >
            {" "}
            EN{" "}
          </div>
          <div> &nbsp;&nbsp; </div>
          <div>
            {" "}
            <UserDropdown />{" "}
          </div>
        </div>
        <div
          className="relative pt-2 mt-2 flex bg-lightGreen-mju w-full"
          style={{ display: isOpenHam ? "flex" : "none" }}
        >
          <div
            className={
              "mx-auto " +
              (windowWidth < 1024 ? " max-w-percent-80" : " max-w-percent-60")
            }
            style={{ display: "block", width: "70%" }}
          >
            <div
              className={
                "bg-lightGreen-mju rounded-lg shadow-sm container relative mx-auto pt-6 px-4 pb-6  " +
                (windowWidth < 1024 ? " " : " px-4")
              }
            >
              <div
                className={
                  "w-full ml-auto mr-auto text-center" +
                  (windowWidth < 1024 ? " " : " px-4")
                }
              >
                <p className="mt-4 text-4xl text-green-mju THSarabun font-bold">
                  Organic Lesson
                </p>
                <hr className="mb-3 mt-2 md:min-w-full border-darkgreen" />
                <div className={"w-full ml-auto mr-auto text-center"}>
                  <img
                    alt="..."
                    src={require("assets/img/menu1.png").default}
                    className="h-auto align-middle border-none max-w-350-px ml-auto mr-auto mt-2"
                  />
                  <img
                    alt="..."
                    src={require("assets/img/menu2.png").default}
                    className="h-auto align-middle border-none max-w-350-px   ml-auto mr-auto  mt-2"
                  />
                  <img
                    alt="..."
                    src={require("assets/img/menu3.png").default}
                    className="h-auto align-middle border-none max-w-350-px   ml-auto mr-auto  mt-2"
                  />{" "}
                  <img
                    alt="..."
                    src={require("assets/img/menu4.png").default}
                    className="h-auto align-middle border-none max-w-350-px   ml-auto mr-auto  mt-2"
                  />{" "}
                  <img
                    alt="..."
                    src={require("assets/img/menu5.png").default}
                    className="h-auto align-middle border-none max-w-350-px   ml-auto mr-auto  mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const matchStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLocale: (lang) => {
    dispatch(setLocale(lang));
  },
});
export default connect(matchStateToProps, mapDispatchToProps)(AdminTopNavBar);
