import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./home.css";
import Card from "./card";
import * as Storage from "../../../../src/services/Storage.service";
import Modal from "react-modal";
const locale = require("react-redux-i18n").I18n;

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    overflowX: "auto",
    backgroundColor: "#F1F5F9",
  },
  overlay: { zIndex: 100, backgroundColor: "rgba(70, 70, 70, 0.5)" },
};

export default function Home() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  function openModal(type, email) {
    if (email !== "guest@mju.ac.th" && email !== "admin@mju.ac.th") {
      setIsOpen(true);
    }
  }

  function afterOpenModal(type) {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const items = [
    {
      id: "01",
      path: "/home/Curriculum/1",
      name: "ปฏิทิน",
      picture: "assets/img/calendar.png",
      nameeng: "Calendar",
    },
    {
      id: "02",
      path: "/home/Curriculum/2",
      name: "การปลูก",
      picture: "assets/img/planting.png",
      nameeng: "Production",
    },
    {
      id: "03",
      path: "/home/Curriculum/3",
      name: "การแปรรูป",
      picture: "assets/img/processing.png",
      nameeng: "Processing",
    },
  ];

  const itemList = items.map((item) => (
    <div
      key={item.id}
      className={
        "w-full px-4 card-max-height" + (windowWidth < 1024 ? " " : " flex-1")
      }
    >
      <Card value={[item.path, item.name, item.picture]}></Card>
    </div>
  ));

  const itemListEng = items.map((item) => (
    <div
      key={item.id}
      className={
        "w-full px-4 card-max-height" + (windowWidth < 1024 ? " " : " flex-1")
      }
    >
      <Card value={[item.path, item.nameeng, item.picture]}></Card>
    </div>
  ));

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  return (
    <>
      <div
        className={
          "relative pt-24 flex bg-darkgreen-mju" +
          (windowWidth < 1024 ? " max-h-screen-50" : " pb-6 max-h-screen-37")
        }
      >
        <div
          className={
            "container relative mx-auto w-10/12" +
            (windowWidth < 1024 ? " " : " px-4")
          }
        >
          <div
            className={
              "w-full ml-auto mr-auto " + (windowWidth < 1024 ? " " : " px-4")
            }
          >
            <h1 className="text-white font-semibold text-5xl">
              {locale.t("home.lblhome")}
            </h1>
            <p className="mt-4 text-lg text-blueGray-200 text-bold  THSarabunBold">
              {locale.t("home.lblwelcome")}
            </p>
          </div>
        </div>
      </div>
      <div className={"relative pt-6 flex bg-darkgreen-mju"  +
          (windowWidth < 1024 ? " max-h-screen-50" : " max-h-screen-37")}>
        <div
          className={
            "mx-auto " +
            (windowWidth < 1024 ? " max-w-percent-80" : " max-w-percent-60")
          }
          style={{ display: "block", width: '70%' }}
        >
          <div
            className={
              "bg-white rounded-lg shadow-sm container relative mx-auto w-8/12 pt-6 px-4 pb-6  " +
              (windowWidth < 1024 ? " " : " px-4")
            }
          >
            <div
              className={
                "w-full ml-auto mr-auto " + (windowWidth < 1024 ? " " : " px-4")
              }
            >
              <h1 className="text-black text-xl">
                {locale.t("home.lbldescription")}
              </h1>
              <p className="mt-4 text-sm text-black THSarabun">
                {locale.t("home.lbldescriptiondetail")}
              </p>
            </div>
          </div>
          <div className={"pt-6 pb-20"}>
            <h1 className="text-white text-base">
              {locale.t("home.lblheadcontent")}
            </h1>
          </div>
        </div>
      </div>
      <div
        className={
          "container mx-auto -mt-16" +
          (windowWidth < 1024 ? " max-w-percent-80" : " px-4 max-w-percent-60")
        }
      >
        {Storage.GetLanguage() === "th" ? (
          <div className="flex flex-wrap">{itemList}</div>
        ) : (
          <div className="flex flex-wrap">{itemListEng}</div>
        )}
      </div>
      <div
        className={"mx-auto -mt-16 px-4 "  + (windowWidth < 1024 ? " max-w-percent-80" : " max-w-percent-60")}
        style={{ display: "block" }}
      >
        <div className={" rounded-lg container relative mx-auto w-8/12 pt-6 pb-6 px-4 shadow-all"} style={{ display : windowWidth < 1024 ? "none" : "block"}}>
          <div className={"pb-4"}>
            <h1 className="text-black text-sm">
              {locale.t("home.lblContentHead")}
            </h1>
          </div>
          <div class="flex" >
            <div class="flex-initial w-84">
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent11")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent12")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent13")}
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex-initial w-84">
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent21")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent22")}
                  </div>
                </li>
              </ul>
            </div>
            <div class="flex-initial w-84">
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent31")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px" }}
                    ></i>{" "}
                    {locale.t("home.lblContent32")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={" rounded-lg container relative mx-auto w-8/12 pt-6 pb-6 mb-6 px-4 shadow-all"} style={{ display : windowWidth < 1024 ? "block" : "none"}}>
          <div className={"pb-4"}>
            <h1 className="text-black text-sm">
              {locale.t("home.lblContentHead")}
            </h1>
          </div>
          <div class="flex" >
            <div class="flex-initial w-84">
            <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent11")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent12")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent13")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent21")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent22")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent31")}
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <i
                      class="fas fa-check-circle"
                      style={{ paddingRight: "6px", paddingTop: '6px' }}
                    ></i>{" "}
                    {locale.t("home.lblContent32")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
